import { getConnectionDispatchers } from '@b2x/core/bundle';

export const getDispatchers = async <T extends object>(connectionName: string) => {
  const dispatchers = await getConnectionDispatchers<T>(connectionName, 30000);

  if (Object.keys(dispatchers).length === 0) {
    throw new Error(`No ${connectionName} dispatchers found`);
  }

  return dispatchers;
};
